import React from 'react';
import { JS_ADVANTAGES } from 'views/javascript/objective-advantages-section/constants';
import { AdvantagesSectionTemplate } from 'components/advantages-section-template';
import { Container } from 'components/container';

export const ObjectiveAdvantagesSection = () => {
    return (
        <Container>
            <AdvantagesSectionTemplate
                title="javascript.objectiveAdvantages"
                firstParagraph="javascript.theAdvantagesOfDeveloping"
                secondParagraph="javascript.javascriptIsPopular"
                advantages={JS_ADVANTAGES}
            />
        </Container>
    );
};
