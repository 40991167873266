import React from 'react';
import { JS_BUSINESS_BENEFITS } from 'views/javascript/constants';
import { BusinessBenefitsSection } from 'modules/business-benefits-section';

export const JavascriptBenefitsSection = () => {
    return (
        <BusinessBenefitsSection
            title="javascript.businessBenefits.title"
            description="javascript.businessBenefits.subTitle"
            data={JS_BUSINESS_BENEFITS}
        />
    );
};
