import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'components/container';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { graphql, useStaticQuery } from 'gatsby';
import { ContactWithExpert } from 'modules/contact-with-expert';

export const ContactWithExpertSection = () => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            mateusz: file(
                relativePath: { eq: "team/mateusz-tkacz-expert.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        width: 242
                        placeholder: NONE
                        layout: CONSTRAINED
                        quality: 100
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <ContactWithExpert
                photo={images.mateusz.childImageSharp}
                photoWidth={242}
                expertRole={formatMessage({ id: 'javascript.expertRoleAlt' })}
                linkedIn={EXTERNAL_REDIRECTS.MATEUSZ_TKACZ_LINKEDIN_URL}
                skype={EXTERNAL_REDIRECTS.SKYPE_CALL_URL}
            />
        </Container>
    );
};
