export const JS_ADVANTAGES = [
    {
        title: 'javascript.indispensableForWebDevelopment',
        description: 'javascript.scriptSupportByAll',
    },
    {
        title: 'javascript.workSpeedAndProductivity',
        description: 'javascript.javascriptAllows',
    },
    {
        title: 'javascript.powerfulInfrastructure',
        description: 'javascript.forTheFirst',
    },
    {
        title: 'javascript.simplicity',
        description: 'javascript.ASimpleTaskCanBeSolved',
    },
    {
        title: 'javascript.convenienceOfUserInterfaces',
        description: 'javascript.fillingOutForms',
    },
    {
        title: 'javascript.easeOfMastering',
        description: 'javascript.perhapsItsAMatter',
    },
];
