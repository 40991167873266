import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
    JAVASCRIPT_DATA,
    JAVASCRIPT_RATING_DATA,
} from 'views/javascript/javascript-language-section/constants';
import { Container } from 'components/container';
import { HeaderThird } from 'components/header-third';
import { SectionTiles } from 'modules/business-benefits-section/components/section-tiles';
import { SectionTilesTemplate } from 'modules/business-benefits-section/components/section-tiles-template';

const SContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SHeaderThird = styled(HeaderThird)`
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 4rem;
    padding: 0 1rem;
`;

const SSectionTilesTemplate = styled(SectionTilesTemplate)`
    margin-bottom: 4.75rem;
    .description {
        margin-bottom: 0;
    }
`;

export const JavaScriptLanguageSection = () => {
    return (
        <SContainer>
            <SSectionTilesTemplate
                title="javascript.javaScriptIsOneOfTheLeading"
                description="javascript.theConsistently"
                tilesData={JAVASCRIPT_DATA}
            />
            <SHeaderThird>
                <FormattedMessage id="javascript.ratingsThatConfirmed" />
            </SHeaderThird>
            <SectionTiles tilesData={JAVASCRIPT_RATING_DATA} />
        </SContainer>
    );
};
