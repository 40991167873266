import React from 'react';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import EfficientIcon from 'svgs/icons/ic-efficient.svg';
import LowerCostIcon from 'svgs/icons/ic-lower-cost.svg';
import OpportunityIcon from 'svgs/icons/ic-opportunity.svg';
import CertifiedIcon from 'svgs/technologies/work-with-mr/award.svg';
import MarketIcon from 'svgs/technologies/work-with-mr/calendar-tick.svg';
import MaintainersIcon from 'svgs/technologies/work-with-mr/code.svg';
import ClientsIcon from 'svgs/technologies/work-with-mr/people.svg';

export const JS_BUSINESS_BENEFITS = [
    {
        icon: <EfficientIcon />,
        text: 'javascript.businessBenefits.efficientCostEffective',
    },
    {
        icon: <LowerCostIcon />,
        text: 'javascript.businessBenefits.lowerCost',
    },
    {
        icon: <OpportunityIcon />,
        text: 'javascript.businessBenefits.opportunityToGet',
    },
];

export const PERKS_LIST = [
    {
        title: 'javascript.workWithMobileReality.onTheMarket',
        icon: <MarketIcon />,
    },
    {
        title: 'javascript.workWithMobileReality.certifiedDevelopers',
        icon: <CertifiedIcon />,
    },
    {
        title: 'javascript.workWithMobileReality.ownersAndMaintainers',
        icon: <MaintainersIcon />,
    },
    {
        title: 'javascript.workWithMobileReality.experiencedInWorking',
        icon: <ClientsIcon />,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    tags: [BLOG_POST_TAGS.BACKEND, BLOG_POST_TAGS.FRONTEND],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'javascript.footer.button',
    },
    categoryBanner: {
        title: 'javascript.footer.title',
        description: 'javascript.footer.description',
        svg: JavascriptSvg,
    },
};
