import React from 'react';
import { JavaScriptView } from 'views/javascript';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/technologies/js.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const JavaScriptPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout location={location} messages={messages}>
            <JavaScriptView {...{ faqData }} />
        </Layout>
    );
};

JavaScriptPage.propTypes = {
    location: object.isRequired,
};

export default JavaScriptPage;

export const Head = () => <SEO tags={TAGS.JAVA_SCRIPT} />;

export const pageQuery = graphql`
    query JavascriptPageQuery {
        allContentfulFaq(filter: { slug: { eq: "technologies/javascript" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
