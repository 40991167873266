import React from 'react';
import CheckIcon from 'svgs/icons/ic-check-circle.svg';
import PyplIcon from 'svgs/icons/ic-pypl.svg';
import StackOverflowIcon from 'svgs/icons/ic-stack-overflow.svg';
import W3TechsIcon from 'svgs/icons/ic-w3-techs.svg';

export const JAVASCRIPT_DATA = [
    {
        icon: <CheckIcon />,
        text: 'javascript.queriesByProgramming',
    },
    {
        icon: <CheckIcon />,
        text: 'javascript.questionsOnProfessionalForums',
    },
    {
        icon: <CheckIcon />,
        text: 'javascript.projectsImplemented',
    },
    {
        icon: <CheckIcon />,
        text: 'javascript.vacanciesForProgrammers',
    },
];

export const JAVASCRIPT_RATING_DATA = [
    {
        icon: <PyplIcon />,
        text: 'javascript.pypl',
        description: 'javascript.pyplDescription',
    },
    {
        icon: <StackOverflowIcon />,
        text: 'javascript.stackOverflow',
        description: 'javascript.stackOverflowDescription',
    },
    {
        icon: <W3TechsIcon />,
        text: 'javascript.w3Techs',
        description: 'javascript.w3TechsDescription',
    },
];
