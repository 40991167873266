import React from 'react';
import { MainSectionTemplate } from 'components/main-section-template';
import { gaTrackedEvents } from 'constants/ga-tracked-events';

export const CodeConsultingSection = () => {
    return (
        <MainSectionTemplate
            title="javascript.javaScriptCodeConsulting"
            subTitle="javascript.theExperienceAndSkills"
            firstParagraph="javascript.javaScriptIsAMultiParadigm"
            googleAnalytics={gaTrackedEvents.JAVASCRIPT.CTA.ESTIMATE_PROJECT}
        />
    );
};
