import React from 'react';
import CandyCrushLogo from 'svgs/js-apps/candy-crush-logo.svg';
import LinkedIndLogo from 'svgs/js-apps/linkedin-logo.svg';
import NetflixLogo from 'svgs/js-apps/netflix-logo.svg';
import UberLogo from 'svgs/js-apps/uber-logo.svg';

export const FAMOUS_APPS = [
    {
        logo: <LinkedIndLogo />,
    },
    {
        logo: <CandyCrushLogo />,
    },
    {
        logo: <NetflixLogo />,
    },
    {
        logo: <UberLogo />,
    },
];
